.container.pre-assessment { width: 95%; max-width: 1100px; align-items: center; margin: 3rem auto; box-sizing: border-box; }
.pre-assessment { 
  & { background-color: #fff; color: $color2; width: 95%; max-width: 1100px; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
  h1 { font-size: 30px; font-weight: 700; line-height: 35px; text-align: center; color: #100F2E; max-width: 70ch; margin: 48px auto; font-family: $fontLato; }
  .assessment-categories { 
    & { display: flex; flex-wrap: wrap; gap: 24px; justify-content: center; }
    .category { 
      & { align-items: start; width: 190px; height: 190px; background-color: #47466C; border-radius: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column; padding-top: 12px; cursor: pointer; }
      .right-tick-img-container { width: 24px; height: 24px; background-color: #F26722; border-radius: 50px; text-align: center; display: flex; justify-content: center; align-items: center; margin-bottom: 8px; }
      .img-container { 
        & { display: flex; justify-content: center; align-items: center; }
        img { width: 86px; height: 86px; }
      }
      .text { font-size: 21px; line-height: 27px; font-weight: 700; text-align: center; color: white; font-family: $primaryfont; padding: 4px 8px; margin: 0; }
     }
     .selected { transition: transform 0.3s ease-in-out; transform: scale(1.05); }
     .non-selected { color: rgba(255, 255, 255, 0.75); opacity: 0.5; }
   }
   .button { 
      & { margin: 45px auto; width: 100%; text-align: center; }
      .cta { cursor: pointer; color: #fff; background-color: #F26722; border: none; border-radius: 5px; padding: 12px 15px; font-family: $primaryfont; font-weight: 700; font-size: 21px; line-height: 25px; text-transform: uppercase; width: 100%; max-width: 200px; }
    }
    .cta:hover { 
      & { background-color: #343333; color: #fff; }
     }
}


@media screen and (max-width: 1100px) {
}


@media screen and (max-width: 830px) {
  .container.pre-assessment { width: 95%; }
  .pre-assessment { 
    & { width: 95%; } 
    .assessment-categories { 
      .category { 
        & { align-self: flex-start; width: 150px; height: 150px; }
        .img-container { 
          & { width: 60px; height: 60px; }
          img { width: 100%; height: 100%; }
         }
        //  .text { font-size: 16px; line-height: 20px; }
      }
      .selected { transition: transform 0.3s ease-in-out; transform: scale(1.1); }
     }
     .pre-assessment .button .cta:hover { background-color: #47466C; color: #fff; }
  }
}



@media screen and (max-width: 650px) {
  .pre-assessment {
    h1 { font-size: 21px; line-height: 28px; margin: 36px auto; }
    .assessment-categories { 
      & { gap: 18px; }
      .category { 
        & { align-self: flex-start; width: 138px; height: 138px; }
        .right-tick-img-container { 
          & { width: 20px; height: 20px; }
          img { width: 80%; }
        }
        .img-container { 
          & { width: 50px; height: 50px; }
          img { width: 100%; height: 100%; object-fit: contain; }
        }
        .text { font-size: 16px; line-height: 20px; }
      }
    }
    .pre-assessment .button .cta:hover { background-color: #47466C; color: #fff; }
  }
}
