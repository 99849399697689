 
 main {
  .assessment-additional.container { max-width: 880px; }
  .assessment-additional {
    & { min-height: 490px; background-color: #fff; width: 96%; }
    .steps-container { max-width: 850px; margin: 5px auto; }
    .steps {
      & { margin: 0 auto 50px; width: 98%; min-height: 380px; }
      .spacer { height: 80px; }
      h1 { font-size: 21px; line-height: 25px; text-align: center; color: #343333; margin: 30px auto; font-family: $fontLato; text-transform: uppercase; }
      .progress-indicator { line-height: 21px; margin: 30px auto 15px; width: 100%; text-align: center; font-family: $fontLato; font-weight: 800; color: #0E435C; font-size: 18px; }

      .question { & { line-height: 36px; display: flex; justify-content: center; align-items: start; min-height: 120px; max-width: 850px; margin: 0 auto; text-align: center; font-family: $fontLato; font-weight: 400; color: #343333; font-size: 24px; min-height: 70px !important; }
        p { margin: 25px auto 25px; max-width: 40ch !important; }
      }

      .radio-container {
        & { width: 65%; margin: 15px auto; display: flex; align-items: center; min-height: 130px; }
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; flex-direction: column; }
          .label {
            & { display: flex; align-items: center; cursor: pointer; width: 90%; /* position: relative; */ }
            p { display: block; /* position: absolute; margin-left: -50%; */ margin: 10px auto; padding: 0; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 400; color: #343333; }
        }
        .MuiRadio-root { margin: 5px; display: flex; width: 30px; height: 30px; }
      }
      .textarea-field { & { max-width: 540px; width: 85%; margin: 15px auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; font-family: "Barlow", sans-serif; }
        textarea { font-family: $primaryfont; font-size: 21px; }
      }
      .slider-container {
        & { width: 86%; margin: 0 auto 120px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 20px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: #343333; }
            p { display: block; font-size: 21px; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: #FC2B38; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #FC2B38; width: calc(100% + 10px); margin-left: -5px; padding: 14px 0; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #FC2B38; }

        .custom-slider .MuiSlider-thumb { background-color: #FC2B38; padding: 9px; border: 3px solid #FC2B38; }
        .custom-slider .MuiSlider-thumb:before { background-color: #FC2B38; border: 3px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #FC2B38; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
        // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
    }
    .step-buttons {
      & { display: flex; justify-content: space-between; width: 90%; max-width: 880px; margin: 30px auto 30px; padding-bottom: 45px; font-family: "Barlow", sans-serif; }
      button { color: #fff; background-color: #F26722; font-weight: 700; font-size: 18px; line-height: 30px; font-family: $primaryfont; padding: 9px 25px; }
      button:disabled { background-color: #979797;  }
      button:hover {  background-color: #100F2E; color: #fff;  }
      .cta-prev {
        & {  border: none; padding: 0; border-radius: 100%; background-color: #fff; min-width: 50px; width: 50px; max-width: 50px; }
        img { height: 100%; width: 100%; }
      }
      .cta-prev:hover { background-color: #fff; color: #F26722; }
      .next-btn.Mui-disabled { background-color: #dedede; color: #6F6F6F; font-weight: 600; }
      .next-btn { background-color: #F26722; color: #FFF; font-weight: 700; }
      .next-btn:hover { background-color: #100F2E; color: #FFF; }
    }
    .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 18px; font-weight: 700; color: #ED2028; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 3ch; max-width: calc(100% - 2ch); }

    .progress-bar {
      & { display: flex; justify-content: space-between; margin: 0 auto; margin-top: 10px; width: 100%; padding-top: 40px; }
      .bar-section { 
        .progress-percentage { color: #100F2E; text-align: right; font-weight: 700; font-size: 21px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; }
        .progress-grid { 
          display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
          .grid-square { width: 10px; height: 10px; margin: 2px; } 
        }
        .bar { height: 6px; margin: 0 5px 10px; }
        .section-title { width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 21px; line-height: 25px; }
      }

      .MuiLinearProgress-root.custom-bar { background-color: #e5e5e5; height: 6px; border-radius: 3px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #100F2E; }
      .sections { & {}
        .section-name { font-size: 18px; margin: 15px auto 5px; }
      }
    }
  }
 }


@media only screen and (max-width: 650px) {

  main .assessment-additional .steps .radio-container { width: 90%; }
  main .assessment-additional .steps .radio-container .slider-labels .label { width: 100%; }
  main .assessment-additional .steps .radio-container .slider-labels .label p { text-align: left; margin: 10px; font-size: 18px; line-height: 31px; }
  main .assessment-additional .steps .question p { text-align: start; }
}


@media only screen and (max-width: 550px) {

   
  main .assessment-additional {
    .steps {
      .question { min-height: 70px !important; font-size: 18px; line-height: 24px; max-width: 100%; display: flex; justify-content: center; align-items: center; }
      .progress-bar .bar-section .section-title { font-size: 12px; }
      .slider-container { margin: 20px auto 90px; }
      .radio-container { margin: 20px auto 42px; min-height: auto; width: 95%; }
      .slider-container .slider-labels .label { width: 14%; }
      .progress-indicator { margin: 40px auto 5px; font-size: 14px; line-height: 16px; }
      .progress-bar { grid-gap: 0; }
      .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
      .progress-bar .bar-section .section-title { font-size: 12px; }
      .slider-container .slider-labels { font-size: 14px; }
      .slider-container .slider-labels .label p.desktop { display: none; }
      .slider-container .slider-labels .label p.mobile { display: block; }
      .radio-container .slider-labels { font-size: 12px; }
      .radio-container .slider-labels .label p.desktop { display: none; }
      .radio-container .slider-labels .label p.mobile { display: block; margin: 10px 5px; }
    }
    .step-buttons { margin: 30px auto 40px; padding-bottom: 30px; }
    .step-buttons button {padding: 9px 15px; }
  }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -15px; }

  main .assessment-additional { & { margin-top: 15px; }
    .steps .textarea-field { width: 100%; box-sizing: border-box; }
    .steps .form-details-text { height: auto; min-height: 120px; }
    .steps .question { font-size: 21px; }
    .steps .question p br { content: ""; }
    .steps .question p br:after { content: " "; }
    .steps .slider-container .slider-labels { font-size: 14px; }
    .steps .step-buttons { padding-bottom: 30px; }
    .step-buttons .all-set-btn { font-size: 18px; padding: 9px 15px; }
    .steps .textarea-field textarea { font-size: 18px; }
  }

}

