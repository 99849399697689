header {
  & { background: #ffffff; }
  .header-wrapper { margin: 0 auto; width: 100%; max-width: 1170px; height: min-content; display: flex; align-items: center; justify-content: space-between; padding: 15px 2.5%; box-sizing: border-box; }
  .logo-left {
    & { width: 100%; max-width: 180px; }
    img { max-width: 180px; width: 100%; height: auto; }
  }
  .assessment-title {
    & { font-family: "Barlow", sans-serif; font-style: normal; font-weight: 700; width: 100%; color: #100F2E; text-align: center; padding: 0; margin: 5px 0px; }
    .logo-text { font-size: 36px; line-height: 42px; color: #100F2E; font-weight: 700; margin: 0 auto; margin-bottom: 5px; }
    .logo-subtext { font-size: 21px; line-height: 25px; letter-spacing: 8px; text-transform: uppercase; margin: 0 auto; color: #100F2E; font-weight: 600; }
  }
  .logo-right {
    & { min-width: 180px; max-width: 180px; text-align: right; text-decoration: none !important; }
    img { width: 100%; max-width: 180px; height: auto; }
    div { color: #000; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-bottom: 1px; }
  }
}
@media only screen and (max-width: 830px) {

  header {
    & { height: auto; flex-direction: column; align-items: flex-start; }
    .header-wrapper {
      .logo-left {
        & { max-width: 50px; width: 100%; }
        img { width: 100%; }
      }
      .assessment-title {
        & { margin: 10px auto; }
        .logo-text { font-size: 28px; line-height: 42px; }
        .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 4px; }
      }
      .logo-right { margin: 0 auto; padding: 0; min-width: 50px; max-width: 50px; width: 100%; }
      .bleat-logo { display: none; }
    }
  }
}
@media only screen and (max-width: 650px) {

  header {
    .header-wrapper {
      .logo-left {
        & { text-align: center; }
      }
      .assessment-title {
        & { margin: 7px auto; }
        .logo-text { font-size: 24px; line-height: 28px }
        .logo-subtext { font-size: 18px; line-height: 25px; }
      }
      .left-logo img { width: 39px; height: 39px; }
    }
  }
}
@media only screen and (max-width: 550px) {

  header {
    .header-wrapper {
      .assessment-title {
        .logo-text { font-size: 24px; font-weight: 700; line-height: normal; }
        .logo-subtext { line-height: 21px; text-align: center; font-family: $fontLato; font-size: 14px; font-weight: 700; line-height: normal; letter-spacing: 4px; text-transform: uppercase; }
      }
    }
  }
}

