main {
 .container { margin: 45px auto 80px; max-width: 1100px; border-radius: 10px; z-index: 1; } 
  // .start { background: #0E435C; }
  .loading { display: flex; justify-content: center; align-items: center; }
  // .container.start { width: 95%; }
  // .container.start { width: 95%; background-color: #0E435C; background-size: cover; background-repeat: no-repeat; background-position: center; border-radius: 10px; overflow: hidden; }
  .start {
    .video {
      // & { padding: 0.5rem; border-radius: 8px 0 0 8px; position: relative; width: auto; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
      // video { margin: 0 auto; }
      & { width: 100%; height: auto; display: flex; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #0E435C; object-fit: cover; transition: all 0.25s; overflow: hidden; box-sizing: border-box; }
      video { margin: 0px auto; position: relative; }
      .thumbnail { /* position: absolute; */ height: 100%; width: 100%; object-fit: cover; overflow: hidden; background-color: #E5E5E5; }
      .playbtn { position: absolute; top: 87%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 45px; width: 223px; }
      // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
    }
    .intro {
      & { background-color: #fff; width: 95%; padding: 15px; display: flex; flex-direction: column; justify-content: start; transition: all 0.25s; box-sizing: border-box; align-items: center; text-align: center; max-width: 890px; margin: 0px auto; border-radius: 10px; }
      h1 { color: rgba(16, 15, 46, 1); font-family: $fontLato; font-size: 32px; font-style: normal; font-weight: 700; line-height: 40px; padding: 10px 0 0; }
      p { font-size: 18px; font-family: "Barlow", sans-serif; font-weight: 600; line-height: 29px; color: rgba(242, 103, 34, 1); }
      .intro-para { & { max-width: 706px; }
        p { font-weight: 300; font-size: 21px; line-height: 30px; color: #100F2E; text-align: center;  font-family: "Barlow", sans-serif; margin: 0px auto;  }
      }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $color3; font-size: 24px; line-height: 27px; max-width: 100%; max-width: 27ch; font-family: "Barlow", sans-serif; }
      .intro-points-list {
        & { margin-left: 10px; padding-left: 10px; font-weight: 400; font-style: normal; color: #343333; font-size: 24px; line-height: 35px; }
        li { color: #000000; font-family: $fontLato ; font-size: 21px; font-style: normal; font-weight: 300; line-height: 30px; text-align: start; }
      }
      .completion {
        & { display: flex; text-align: center; justify-content: center; flex-direction: column; gap: 10px; margin: 35px auto 15px; color: #343333; font-weight: 400; font-size: 18px; width: 100%; align-items: center; }
        .cta { display: block; background-color: #F26722; border-radius: 6px; color: #fff; text-decoration: none; width: 100%; max-width: 200px; height: 50px; text-align: center; font-family: $fontLato; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 12px; margin-left: 0; transition: 0.3s; letter-spacing: 1px; line-height: 25.2px; }
        .cta:hover { background-color: #100F2E; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { display: flex; align-items: center; justify-content: center; margin: 15px 25px; color: #100F2E;  }
          .clock-icon { width: 20px; height: 20px; margin-top: 3px; padding-right: 6px; }
          span { font-size: 18px; font-weight: 600; font-family: $fontLato; line-height: 35px; }
        }
      }
      .para-container {
        & { width: auto; text-align: center; padding-bottom: 15px; }
        .para { font-size: 18px; font-weight: 300; line-height: 24px; color: #000000; padding: 5px 25px; font-family: $fontLato; margin: 0px; }
      }
    }
    // .para-container{
    //   p{
    //     color: #5A5A5A;
    //     font-size: 24px;
    //     font-weight: 400;
    //     margin: 0.5rem;
    //     text-align: center;
    //   }
    // }
    //   .completion { 
    //     & { display: flex; flex-direction: row; align-items: center; color: #4e4229; font-weight: 400; font-size: 18px; padding: 1.5rem 0rem; }
    //   }
    //   .btn-container{ & { padding-bottom: 2rem; }
    //   .cta { display: block; background: #348CFE; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: $primaryfont; font-size: 20px; font-weight: 700; padding: 12px 0 12px; margin-left: 0; }
    //     .cta:hover { background: #fcb900; transition: 0.3s; color: #fff; }
    // }
    //     .time-estimate {
    //       & { display: flex; margin: 0; background-color: #FDE6A2; padding: 0.3rem 0.75rem 0.3rem 0.75rem; border-radius: 30px; }
    //       .clock-icon { width: 22px; height: 22px; }
    //       .img-container { padding-top: 0.1rem; padding-right: 0.25rem ; padding-left:0.5rem; }
    //     }
  }
}
@media only screen and (max-width:1110px) {

  main .start .intro {
    & { padding: 0 45px; }
    h1 { font-size: 30px; line-height: 34px; text-align: left; }
    .intro-points-list {
      & { font-size: 18px; }
      li { line-height: 30px; }
    }
    .completion { margin-top: 15px; }
  }
}
@media only screen and (max-width:550px) {

  main .completion .cta { margin: 0 auto !important; width: 90%; }
}
@media only screen and (min-width:831px) {
  // main .bleat-logo-mobile { display: none; }
  // main .container.start { margin: 30px auto; }
}
@media only screen and (max-width:830px) {

  main {
    // .container.start { margin: 15px auto; }
    .start {
      .intro {
        & { width: 95%; box-sizing: border-box; margin: 0 auto; padding: 15px; height: auto; border-radius:10px; }
        h1 { text-align: center; font-size: 28px; margin: 0 auto 15px; padding-top: 30px; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        h1 br { content: ""; }
        h1 br:after { content: " "; }
        .intro-para {
          & { max-width: 35ch; margin: 5px auto; }
          p{ text-align: center; font-weight: 400; font-size: 16px; line-height: 31px; }
        }
        .intro-points-list { width: auto; margin: 15px auto; padding-left: 0px; text-align: center; padding: 15px 30px; }
        .completion {
          & { flex-direction: column; margin: 30px auto 0 auto; }
          .time-estimate { margin: 15px auto; }
          .cta { margin-bottom: 0; align-self: center; }
        }
      }
    }
    .start {
      // & { flex-direction: column; height: auto; width: 95%; }
      .video {
        & { width: 100%; box-sizing: border-box; height: auto; border-radius: 10px 10px 0 0; }
        // .playbtn { top: 50%; }
      }
    }
    .results .result-text, .dashboard .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
    .thank-you {
      h1 { font-size: 18px; line-height: 30px; }
      p { font-size: 16px; }
    }
  }
  main .logo-left-container { display: none; }
  main .start .video video { width: 100%; }
  //margin: 0 -10%; object-fit: cover; }
  main .start .video .thumbnail { width: auto; width: 100%; height: 100%; }
  main .start .intro .para-container .para { font-size: 13px;  line-height: 21px;  padding: 5px 11px; text-align: left; }
}
@media only screen and (max-width:650px) {

  main .start {
    .video .playbtn { width: 220px; height: auto; }
    .intro {
      // h1 { text-align: center; }
      // h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
    .para-container p { font-size: 18px; }
  }
}
@media only screen and (max-width:550px) {

  // main .start { margin-top: 15px; }
  main .start .video {
    & { height: 50vw; height: auto; width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    .close-video { right: 5px !important; top: 5px !important; }
  }
  // main .start .video{
  //   & { border-radius: 5px; height: 325px; width: 100%; }
  //   .thumbnail { 
  //     & { object-fit: fill;  height: 100%; width: 80%; border-radius: 20px;}
  //   }
  // }
  main .start {
    .video {
      & { border-radius: 5px; min-height: 230px; height: auto; width: 100%; }
      .thumbnail {
        & { object-fit: cover; height: 100%; width: 100% }
      }
    }
    .intro {
      & { margin-top: 10px; border-radius: 5px; }
      h1 { font-size: 21px; line-height: 30px; margin: 0px auto; font-weight: 700; padding: 15px 15px 0 0; text-align: center; }
      h2 { font-size: 17px; max-width: 34ch; }
      .intro-para { font-size: 14px; line-height: 20px; max-width: 28ch; }
      .intro-points-list { 
        & { width: 90%; margin: auto; padding-left: 20px; }
        li { font-size: 18px; font-weight: 300; line-height: 30px; max-width: 30ch; margin: 0 auto; }
      }
      .completion {
        .cta { font-size: 21px; font-weight: 700; width: 60%; max-width: 60%; min-width: 60%; }
        .time-estimate {
          & { align-items: center; }
          span { font-size: 18px; font-style: normal; font-weight: 700; line-height: 35px; }
        }
      }
      img { margin: 0 4px 0 0; }
    }
  }
}
@media only screen and (max-width: 500px) {

  main .start {
    .video .playbtn { width: 220px; height: auto; }
  }
}