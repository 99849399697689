 main{ .thank-you {
    & { min-height: 381px; margin-top: 80px !important; width: 90%; max-width: 1008px !important; padding-bottom: 15px !important; background: #fff; border-radius: 10px !important; display: flex; flex-direction: column; align-items: center; justify-content: center; }
    .tick { & { text-align: center; border-radius: 8px; }
      img { width: 57px; height: 57px; }
    }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: $fontLato; font-size: 36px; font-weight: 700; line-height: 43px; color: #27282A; padding: 28px 15px 28px; }
    // p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: #202020; }
    p { width: 52ch; margin: 0 auto; text-align: center; color: #000000; font-family: $primaryfont; font-size: 21px;font-weight: 500; line-height: 1.46em; }
    .back-btn { 
      & { text-align: center; margin: 2rem auto 1rem auto; }
      a { border-bottom: 2px solid #F26722; text-transform: uppercase; font-weight: 700; cursor: pointer; text-decoration: none; color: #202020; font-size: 14px; line-height: 17px; }
    }
  }

 main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 24px; max-width: 30ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 17px; }
  
  .schedule-container {
    & { display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 358px; box-sizing: border-box; padding: 40px; text-align: center; background-color: #616161; margin: auto; width: 816px; max-width: 1120px; padding-bottom: 30px; border-radius: 30px; }
    h4 { text-align: center; color: #FFFFFF; font-size: 24px; line-height: 36px; font-weight: 600; }
    img { width: 46px; height: 38px; }
    .cta-btn {
      & { width: 220px; height: 50px; border-radius: 25px; background-color: #FC2B38; color: white; border: none; font-size: 16px; font-weight: 600; }
      &:hover { background: #76CEFD; color: $color3; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: $primaryfont; font-size: 18px; border-radius: 3px; font-weight: bold; }
    }
  }

}


@media only screen and (max-width: 650px) {

  main .thank-you h1 { font-size: 24px; }
  
}


